var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "header" }, [
      _vm.mainTheme == "darkMode"
        ? _c("img", {
            attrs: { src: require("@/assets/logo.png"), width: "300px" },
          })
        : _c("img", {
            attrs: { src: require("@/assets/logo.png"), width: "300px" },
          }),
      _c("div", { staticClass: "description" }, [
        _vm._v(_vm._s(_vm.$t("xboot"))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }